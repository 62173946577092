const hamburger = document.querySelector(".hamburger");
const navLinks = document.querySelector(".nav-links");
const body = document.body;

hamburger.addEventListener("click", () => {
	navLinks.classList.toggle("active");
	hamburger.classList.toggle("active");
	body.classList.toggle("no-scroll");
});

const respMenuLi = document.querySelectorAll(".nav-links li").forEach((n) =>
	n.addEventListener("click", () => {
		hamburger.classList.remove("active");
		navLinks.classList.remove("active");
		body.classList.remove("no-scroll");
	})
);

// contact
document
	.getElementById("contact-form")
	.addEventListener("submit", function (event) {
		event.preventDefault();
		document.getElementById("thankYouMessage").style.display = "block";
		this.reset();
	});
